import {
  MazeMapCampus,
  MazeMapInstructions,
  MazeMapPOI,
  MazeMapPOIType,
  MazeMapRouteArgument,
  SearchControllerOptions,
  SlimMazeMapPOI
} from "MazeMapTypes";
import { LngLat } from "mapbox-gl";
import { RouteUtil } from "./RouteUtil";
import { LatLngRouteArgument, MapDataService, POIIdRouteArgument, RouteArgumemt } from "./MapDataService";
import { RouteOptions } from "../app/models/RouteOptions";
import { MAZEMAP_CAMPUS_COLLECTION_ID } from "../constants";
import {
  BUILDING_POI_PROPERTIES,
  Campus,
  Category,
  CommonPOIProperties,
  INDOOR_POI_PROPERTIES,
  OUTDOOR_POI_PROPERTIES,
  PointOfInterest,
  RouteDetail,
  SIMPLE_CATEGORY_POI_PROPERTIES,
  SimpleCategoryPOI
} from "../types/UQMapsTypes";
import { LatLng } from "../app/models/LatLng";

const MazeMapDataService: MapDataService = {

  /**
   * Retrieves a campus by its ID. Converts the MazeMapCampus returned by the API to a Campus object
   *  via the helper function.
   *
   * @param {string} campusId - The ID of the campus to retrieve.
   * @returns {Promise<Campus>} A promise that resolves to the retrieved Campus.
   * @throws {Error} If the campus cannot be retrieved with the given ID.
   */
  getCampus: async (campusId: string): Promise<Campus> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const mazemapCampus = await Mazemap.Data.getCampus(campusIdNum);
      return convertCampus(mazemapCampus);
    } catch (e) {
      throw new Error(`Cannot get campus with ID: "${campusId}"`);
    }
  },

  /**
   * Retrieves all campuses. Converts the MazeMapCampuses returned by the API to Campus objects via the helper function.
   *
   * @returns {Promise<Campus[]>} A promise that resolves to an array of Campus objects.
   */
  getCampuses: async (): Promise<Campus[]> => {
    const mazemapCampuses = await Mazemap.Data.getCampuses(MAZEMAP_CAMPUS_COLLECTION_ID);
    return mazemapCampuses.map(campus => convertCampus(campus));
  },

  /**
   * Retrieves a category by its ID.
   *
   * @param {string} categoryId - The ID of the category to retrieve.
   * @returns {Promise<Category>} A promise that resolves to the retrieved Category.
   * @throws {Error} If no category is found for the given ID.
   */
  getCategory: async (categoryId: string): Promise<Category> => {
    try {
      const categoryIdNum = stringToNumber(categoryId);
      const category = await Mazemap.Data.getTypeById(categoryIdNum);
      return {
        id: category.id?.toString() ?? "NO_ID",
        title: category.title ?? "Unnamed Category",
        type: "Category"
      };
    } catch (e) {
      throw new Error(`No category found for ID: "${categoryId}"`);
    }
  },

  /**
   * Retrieves all categories for a given campus by its ID.
   *
   * @param {string} campusId - The ID of the campus to retrieve categories for.
   * @returns {Promise<Category[]>} A promise that resolves to an array of Category objects.
   * @throws {Error} If the campus cannot be retrieved with the given ID.
   */
  getCategories: async (campusId: string): Promise<Category[]> => {
    let categoryIds: number[] | undefined = undefined;
    try {
      const campusIdNum = stringToNumber(campusId);
      const campus = await Mazemap.Data.getCampus(campusIdNum);
      categoryIds = campus.properties.poiTypesSelectableInApp;
    } catch (e) {
      throw new Error(`Cannot get campus with ID: "${campusId}"`);
    }
    if (!categoryIds?.length) return [];
    return Promise.all(categoryIds.map(catId => MazeMapDataService.getCategory(catId.toString())));
  },

  /**
   * Retrieves a Point of Interest (POI) by its ID.
   *
   * @param {string} id - The identifier or legacy POI ID of the POI to retrieve.
   * @returns {Promise<PointOfInterest>} A promise that resolves to the retrieved PointOfInterest.
   * @throws {Error} If the POI cannot be found with the given ID.
   */
  getPoi: async (id: string): Promise<PointOfInterest> => {
    const mazeMapPoi = await getMazeMapPOI(id);
    return convertPOI(mazeMapPoi);
  },

  /**
   * Retrieves a Point of Interest (POI) at a given location and z-level.
   *
   * @param {LngLat} lngLat - The longitude and latitude of the location to retrieve the POI.
   * @param {number} zLevel - The z-level (floor) at the location to retrieve the POI.
   * @returns {Promise<PointOfInterest | undefined>} A promise that resolves to the retrieved PointOfInterest or
   *  undefined if no POI is found.
   */
  getPoiAt: async (lngLat: LngLat, zLevel: number): Promise<PointOfInterest | undefined> => {
    const feature = await Mazemap.Data.getPoiAt(lngLat, zLevel);

    // no POI found here...
    if (!feature) return undefined;

    if (!feature.properties.title) {
      // fix for ITSADSSD-36326 POIs with no title.
      return undefined;
    }
    return convertPOI(feature);
  },

  /**
   * Retrieves Points of Interest (POIs) for a given category and campus.
   *
   * @param {string} categoryId - The ID of the category to retrieve POIs for.
   * @param {string} campusId - The ID of the campus to retrieve POIs for.
   * @param {number} start - The starting index for pagination.
   * @param {number} rows - The number of rows to retrieve for pagination.
   * @returns {Promise<PointOfInterest[]>} A promise that resolves to an array of PointOfInterest objects.
   * @throws {Error} If the category POIs cannot be retrieved for the given category ID and campus ID.
   */
  getPoisForCategory: async (
    categoryId: string,
    campusId: string,
    start: number,
    rows: number
  ): Promise<PointOfInterest[]> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const categoryIdNum = stringToNumber(categoryId);

      // this is a kludge in attempt to page MazeMap's rubbish categories API.

      // first get all results
      const { features: allResults } = await Mazemap.Data.getPoisOfTypeAsGeoJSON({
        poiTypeId: categoryIdNum,
        campusId: campusIdNum
      });

      // extract results for the page we want to return
      const slicedResults = allResults.slice(start, start + rows);

      // now fetch rich versions for the selected subset
      return await Promise.all(
        slicedResults.map(async s => {
          if (!s.properties.poiId) throw new Error("No poiId");
          const mazeMapPoi = await Mazemap.Data.getPoi(s.properties.poiId);
          const poi = convertPOI(mazeMapPoi);

          // ensure that the category in question is listed first. This will ensure that the correct icon will be
          // displayed in the search results pane in cases where a POI belongs to multiple categories.
          if (poi.properties.categories) {
            const category = poi.properties.categories.find(x => x.id === categoryId);
            if (category) {
              const otherCategories = poi.properties.categories.filter(x => x !== category);
              poi.properties.categories = [category, ...otherCategories];
            }
          }
          return poi;
        })
      );
    } catch (e) {
      throw new Error(`Cannot get category POIs for ID "${categoryId}", with campus ID: "${campusId}"`);
    }
  },

  /**
   * Retrieves all Points of Interest (POIs) for a given category and campus.
   *
   * @param {string} categoryId - The ID of the category to retrieve POIs for.
   * @param {string} campusId - The ID of the campus to retrieve POIs for.
   * @returns {Promise<SimpleCategoryPOI[]>} A promise that resolves to an array of SimpleCategoryPOI objects.
   * @throws {Error} If the category POIs cannot be retrieved for the given category ID and campus ID.
   */
  getAllCategoryLocations: async (categoryId: string, campusId: string): Promise<SimpleCategoryPOI[]> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const categoryIdNum = stringToNumber(categoryId);

      // fetch the category and all POIs for the category.
      const [category, featuresCollection] = await Promise.all([
        MazeMapDataService.getCategory(categoryId),
        Mazemap.Data.getPoisOfTypeAsGeoJSON({
          poiTypeId: categoryIdNum,
          campusId: campusIdNum
        })
      ]);

      // map into desired result format
      return featuresCollection.features.map(f => ({
        type: "Feature",
        geometry: f.geometry,
        properties: {
          // this is a bit tricky, ideally we would want to use our own identifier however it isn't available here
          // without doing a full POI lookup which would be very slow over this whole list. So instead, we'll jam
          // in the MazeMap PoiId and, when performing a rich lookup later, it should handle it fine thanks to the
          // legacy fallback.
          id: f.properties.poiId?.toString() ?? "NO_ID",
          category,
          type: SIMPLE_CATEGORY_POI_PROPERTIES
        }
      }));
    } catch (e) {
      throw new Error(`Cannot get category POIs for ID "${categoryId}", with campus ID: "${campusId}"`);
    }
  },

  /**
   * Searches for Points of Interest (POIs) on a given campus based on a search term.
   *
   * @param {string} campusId - The ID of the campus to search POIs in.
   * @param {string} searchTerm - The term to search for POIs.
   * @param {number} start - The starting index for pagination.
   * @param {number} rows - The number of rows to retrieve for pagination.
   * @param {LatLng} nearTo - The location to boost search results by proximity.
   * @returns {Promise<PointOfInterest[]>} A promise that resolves to an array of PointOfInterest objects.
   * @throws {Error} If an error occurs during the search.
   */
  getPoisBySearch: async (
    campusId: string,
    searchTerm: string,
    start: number,
    rows: number,
    nearTo: LatLng
  ): Promise<PointOfInterest[]> => {
    try {
      // Setup the search controller and execute the search
      const campusIdNum = stringToNumber(campusId);
      const options: SearchControllerOptions = {
        campusid: campusIdNum,
        rows: rows,
        start: start,
        withcampus: false,
        withbuilding: true,
        withpois: true,
        withtype: false,
        resultsFormat: "geojson",
        boostbydistance: true,
        searchdiameter: 50,
        ...nearTo
      };
      const searchController = new Mazemap.Search.SearchController(options);
      const searchResult = await searchController.search(searchTerm);

      // If there are results, convert them to PointOfInterest objects.
      if (searchResult.results?.features) {
        return await Promise.all(
          searchResult.results.features.map(poi => convertSlimPOI(poi))
        );
      }
    } catch (e) {
      console.log("Search error occurred", e);
    }
    return [];
  },

  /**
   * Retrieves a route between two points of interest (POI) or coordinates.
   *
   * @param {POIIdRouteArgument | LatLngRouteArgument} origin - The starting point of the route, either a POI ID or
   *  coordinates.
   * @param {POIIdRouteArgument | LatLngRouteArgument} destination - The ending point of the route, either a POI ID or
   *  coordinates.
   * @param {RouteOptions} [options] - Optional route options, such as avoiding stairs.
   * @returns {Promise<RouteDetail>} A promise that resolves to the detailed route information.
   * @throws {Error} If the route cannot be retrieved.
   */
  getRoute: async (
    origin: POIIdRouteArgument | LatLngRouteArgument,
    destination: POIIdRouteArgument | LatLngRouteArgument,
    options?: RouteOptions
  ): Promise<RouteDetail> => {
    const [src, dst] = await Promise.all([convertRouteArgument(origin), convertRouteArgument(destination)]);

    const mappedOptions: Mazemap.Data.RouteOptions = {
      avoidStairs: options?.avoidStairs
    };

    const [route, instructions] = await Promise.all([
      Mazemap.Data.getRouteJSON(src, dst, mappedOptions),
      (async (): Promise<MazeMapInstructions | undefined> => {
        try {
          return await Mazemap.Data.getDirections(src, dst, mappedOptions);
        } catch (error) {
          // Instructions only works on campus.
          // TODO: as with the steps, we may be able to make some from the geometry.
          return undefined;
        }
      })()
    ]);

    const routeDetail = RouteUtil.toRouteDetail(route);
    if (instructions) {
      routeDetail.instructions = instructions.routes[0].legs[0].steps;
    }
    return routeDetail;
  }
};

// ------------- HELPERS ------------- //

/**
 * Converts a MazeMapCampus to a Campus.
 *
 * @param {MazeMapCampus} campus - The MazeMapCampus to convert.
 * @returns {Campus} The converted Campus.
 */
function convertCampus(campus: MazeMapCampus): Campus {
  return {
    type: "Feature",
    geometry: campus.geometry,
    properties: {
      id: campus.properties.id?.toString() ?? "CAMPUS_NO_ID",
      name: campus.properties.name ?? "Unnamed Campus",
      defaultZLevel: campus.properties.defaultZLevel ?? 0
    }
  };
}

/**
 * Converts a SlimMazeMapPOI to a PointOfInterest so the RichMazeMapPoiProperties can be retrieved.
 *
 * @param {SlimMazeMapPOI} poi - The POI to convert.
 * @returns {Promise<PointOfInterest>} A promise that resolves to the converted PointOfInterest.
 * @throws {Error} If the POI type is not "poi" or "building" and therefore cannot be handled.
 */
async function convertSlimPOI(poi: SlimMazeMapPOI): Promise<PointOfInterest> {

  /**
   * Handles the conversion of a POI by its ID.
   *
   * @param {number} poiId - The ID of the POI to convert.
   * @returns {Promise<PointOfInterest>} A promise that resolves to the converted PointOfInterest.
   */
  const handlePOI = async (poiId: number): Promise<PointOfInterest> => {
    const mazeMapPoi = await Mazemap.Data.getPoi(poiId);
    return convertPOI(mazeMapPoi);
  };

  if (poi.properties.type === "poi" && poi.properties.poiId) {
    return handlePOI(poi.properties.poiId);
  }

  if (poi.properties.type === "building" && poi.properties.id) {
    const building = await Mazemap.Data.getBuildingPoiJSON(poi.properties.id);
    if (building.poiId) {
      return handlePOI(building.poiId);
    }
  }

  throw new Error(`Unhandled POI Type: "${poi.properties.type}". Cannot get rich information`);
}

/**
 * Retrieves a MazeMapPOI from the MazeMap API by its identifier or legacy POI ID.
 *
 * @param {string} id - The identifier or legacy POI ID of the POI to retrieve.
 * @returns {Promise<MazeMapPOI>} A promise that resolves to the retrieved MazeMapPOI.
 * @throws {Error} If the POI cannot be found with the given ID.
 */
async function getMazeMapPOI(id: string): Promise<MazeMapPOI> {
  /**
   * Attempts to retrieve a POI assuming the given ID is an identifier.
   *
   * @returns {Promise<MazeMapPOI>} A promise that resolves to the retrieved POI.
   * @throws {Error} If no POI is found with the given identifier.
   */
  const getPoiAssumingIdentifier = async (): Promise<MazeMapPOI> => {
    const [poi] = await Mazemap.Data.getPois({ identifier: id });
    if (poi as MazeMapPOI | undefined) return poi;
    throw new Error("No POI found assuming the given ID was an identifier");
  };

  /**
   * Attempts to retrieve a POI assuming the given ID is a legacy POI ID.
   *
   * @returns {Promise<MazeMapPOI>} A promise that resolves to the retrieved POI.
   */
  const getPoiAssumingLegacyPoiId = async (): Promise<MazeMapPOI> => {
    const poiIdNum = stringToNumber(id);
    return await Mazemap.Data.getPoi(poiIdNum);
  };

  try {
    return await Promise.any([getPoiAssumingIdentifier(), getPoiAssumingLegacyPoiId()]);
  } catch (e) {
    // give up.
    throw new Error(`Cannot find POI with ID: "${id}"`);
  }
}

/**
 * Converts a MazeMapPOI to a PointOfInterest. We are only interested in determining if the provided MazeMapPOI is
 *   a building, an indoor, or an outdoor POI, by checking against MazeMapPOI.properties.kind. Unfortunately, the
 *   MazeMap API response for properties.kind changes over time, so we cannot ever be certain on what will be returned.
 *
 * @see https://api.mazemap.com/js/v2.2.1/docs/#api-data:-pois
 *
 * @param {MazeMapPOI} poi - The POI to convert.
 * @returns {PointOfInterest} The converted PointOfInterest.
 */
function convertPOI(poi: MazeMapPOI): PointOfInterest {
  const commonProps: CommonPOIProperties = {
    id: poi.properties.identifier ?? poi.properties.poiId?.toString() ?? "NO_ID",
    campusId: poi.properties.campusId?.toString() ?? "NO_CAMPUS_ID",
    title: poi.properties.title ?? "",
    otherNames: poi.properties.names,
    description: poi.properties.description,
    infoUrl: poi.properties.infoUrl,
    infoUrlLabel: poi.properties.infoUrlText,
    categories: poi.properties.types?.map(convertCategory)
  };

  if (poi.properties.kind === "building") {
    return {
      type: "Feature",
      geometry: poi.geometry,
      properties: {
        type: BUILDING_POI_PROPERTIES,
        ...commonProps
      }
    };
  }

  if (poi.properties.buildingId && poi.properties.floorName) {
    return {
      type: "Feature",
      geometry: poi.geometry,
      properties: {
        type: INDOOR_POI_PROPERTIES,
        floorLabel: poi.properties.floorName,
        floorZLevel: poi.properties.zLevel ?? 0,
        buildingName: poi.properties.buildingName ?? "",
        buildingId: poi.properties.buildingId.toString(),
        ...commonProps
      }
    };
  }

  return {
    type: "Feature",
    geometry: poi.geometry,
    properties: {
      type: OUTDOOR_POI_PROPERTIES,
      ...commonProps
    }
  };
}

/**
 * Converts a MazeMapPOIType to a Category.
 *
 * @param {MazeMapPOIType} category - The category to convert.
 * @returns {Category} The converted category.
 */
function convertCategory(category: MazeMapPOIType): Category {
  return {
    id: category.poiTypeId?.toString() ?? "NO_CATEGORY_ID",
    title: category.name ?? "Unnamed Category",
    type: "Category"
  };
}

/**
 * Converts a route argument to a MazeMapRouteArgument. LatLngRouteArguments are converted directly,
 *  while POIIdRouteArguments are converted by retrieving the POI from MazeMap and extracting the poiId.
 *
 * @param {RouteArgumemt} arg - The route argument to convert.
 * @returns {Promise<MazeMapRouteArgument>} A promise that resolves to the converted MazeMapRouteArgument.
 * @throws {Error} If the route argument type is unknown.
 */
async function convertRouteArgument(arg: RouteArgumemt): Promise<MazeMapRouteArgument> {
  switch (arg.type) {
    case "LatLngRouteArgument":
      return {
        lngLat: {
          lat: arg.lngLat.lat,
          lng: arg.lngLat.lng
        },
        zLevel: arg.zLevel
      };

    case "POIIdRouteArgument": {
      const poi = await getMazeMapPOI(arg.id);
      return {
        poiId: poi.properties.poiId as number
      };
    }
    default:
      throw new Error("Unknown route argument type.");
  }
}

/**
 * Converts a string to a number.
 *
 * @param {string} input - The string to convert to a number.
 * @returns {number} The converted number.
 * @throws {Error} If the input cannot be converted to a number.
 */
function stringToNumber(input: string): number {
  const num = Number(input);
  if (isNaN(num)) {
    throw new Error(`Cannot convert "${input}" to a number`);
  }
  return num;
}

export default MazeMapDataService;
