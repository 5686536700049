import React, { ReactElement, useEffect, useRef } from "react";
import styles from "./searchField.module.scss";
import classnames from "classnames";
import { ReactComponent as ArrowBack } from "../../../assets/Icons/arrow-left.svg";
import IconButton from "../../../components/IconButton";
import { ReactComponent as Search } from "../../../assets/Icons/search-1.svg";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import { ReactComponent as PinSearch } from "../../../assets/Icons/pin.svg";
import { ReactComponent as RoadSignTurnRight } from "../../../assets/road-sign-turn-right-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  CATEGORY_RESULTS_LAYER_NAME,
  fetchSearchResults,
  SEARCH_RESULTS_LAYER_NAME,
  updateSearchTerm,
  endSearching, deselectSearchResult
} from "../../../store/search/searchSlice";
import { removeLayer } from "../../../store/layers/layerSlice";
import { AnalyticsHandler } from "../../../services/analytics/google-analytics/AnalyticsHandler";
import { AnalyticsOptionsImpl } from "../../../services/analytics/google-analytics/AnalyticsOptions";
import { dropPinLayerName } from "../../map/dropPin";
import { closeItemDetailsWayfinding, initiateWayfinding } from "../../../store/wayFinding/wayFindingSlice";
import { DIRECTIONS, PROGRESS, SEARCH, SEARCH_BACK, SEARCH_IN, SEARCH_STOP } from "../../../constants";
import { closeItemDetails } from "../../../store/map/mapSlice";

interface SearchFieldProps {
  panelOpen: boolean;
  clickPanelSection(visibility: boolean): void;
}

export default function SearchField(props: SearchFieldProps): ReactElement {
  const dispatch = useDispatch();
  const { clickPanelSection, panelOpen } = props;
  const inputEl = useRef<HTMLInputElement>(null);
  const campuses = useSelector((state: RootState) => state.map.campuses);
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
  const campusId = useSelector((state: RootState) => state.map.campusId);
  const centre = useSelector((state: RootState) => state.map.centre);
  const itemDetail = useSelector((state: RootState) => (state.map.openItemDetail ? state.map.itemDetail : undefined));
  const isLoading = useSelector((state: RootState) => state.search.isLoading);
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);
  const wayfinding = useSelector((state: RootState) => state.settings.siteFeatures.wayfinding);
  const currentLocation = campuses.find(campus => campusId === campus.properties.id);
  const currentCampusName = currentLocation?.properties.name ?? "";
  const timeoutDuration = 300;

  function textSearchChanged(searchTerm: string): void {
    dispatch(updateSearchTerm(searchTerm));
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (campusId) dispatch(fetchSearchResults(campusId, searchTerm, 0, centre));
      AnalyticsHandler.handleEvent(new AnalyticsOptionsImpl("Search", "SearchBox", "Submit", searchTerm));
      dispatch(closeItemDetails());
      dispatch(removeLayer(dropPinLayerName));
      dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
    }, timeoutDuration);
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  function handleClose(endSearch: boolean): void {
    if (isLandscape) clickPanelSection(true);
    dispatch(closeItemDetails());
    dispatch(removeLayer(dropPinLayerName));
    dispatch(removeLayer(CATEGORY_RESULTS_LAYER_NAME));
    dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
    dispatch(updateSearchTerm(""));
    if (endSearch) {
      dispatch(endSearching());
    } else {
      focus();
    }
  }

  const startWayFinding = (): void => {
    if (isLandscape) clickPanelSection(true);
    dispatch(initiateWayfinding(itemDetail));
  };

  const onFocus = (): void => {
    if (isLandscape) clickPanelSection(true);
  };

  const focus = (): void => {
    inputEl.current?.focus();
  };

  const handleCloseDetails = (): void => {
    dispatch(closeItemDetails());
    dispatch(closeItemDetailsWayfinding());
    dispatch(deselectSearchResult());
    dispatch(removeLayer(dropPinLayerName));
    dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
  };

  return (
    <div className={styles.searchFieldWrap}>
      <div className={styles.searchFieldLeftArea}>
        {searchTerm && <ArrowBackButton onClick={(): void => handleClose(true)} />}
        {!searchTerm && isLandscape && panelOpen && <ArrowBackButton onClick={(): void => clickPanelSection(false)} />}
        {!searchTerm && !isLandscape && <LocationButton />}
        {!searchTerm && isLandscape && !panelOpen && <LocationButton />}
      </div>
      <input
        tabIndex={1}
        type={"text"}
        className={styles.searchFieldText}
        value={searchTerm}
        ref={inputEl}
        onChange={(event): void => textSearchChanged(event.target.value)}
        onFocus={onFocus}
        placeholder={`${SEARCH_IN} ${currentCampusName}`}
      />
      <div className={styles.searchFieldRightArea}>
        <SearchButton />
        {(searchTerm || wayfinding) && (
          <div className={styles.directionWrap}>
            {!searchTerm ? (
              !itemDetail ? (
                <DirectionButton onClick={startWayFinding} />
              ) : (
                <RemoveResultsButton onClick={handleCloseDetails} />
              )
            ) : isLoading ? (
              <ProgressButton />
            ) : (
              <RemoveResultsButton onClick={(): void => handleClose(false)} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}



interface SimpleButtonProps {
  onClick(): void;
}

function ArrowBackButton({ onClick }: SimpleButtonProps): ReactElement {
  return <IconButton icon={<ArrowBack />} ariaLabel={SEARCH_BACK} className={styles.arrowLeftIcon} onClick={onClick} />;
}

function LocationButton(): ReactElement {
  return <PinSearch className={classnames(styles.searchLocation, styles.locationIcon)} />;
}

function SearchButton(): ReactElement {
  return (
    <IconButton
      icon={<Search />}
      onClick={focus}
      ariaLabel={SEARCH}
      className={classnames(styles.searchIcon, styles.searchWrap)}
    />
  );
}

function ProgressButton(): ReactElement {
  return (
    <IconButton
      ariaLabel={PROGRESS}
      className={classnames("uq-loading-spinner uq-loading-spinner--small", styles.progressIcon)}
    />
  );
}

function RemoveResultsButton({ onClick }: SimpleButtonProps): ReactElement {
  return (
    <IconButton
      onClick={onClick}
      icon={<Close />}
      ariaLabel={SEARCH_STOP}
      className={styles.closeIcon}
    />
  );
}

function DirectionButton({ onClick }: SimpleButtonProps): ReactElement {
  return (
    <IconButton
      onClick={onClick}
      icon={<RoadSignTurnRight />}
      ariaLabel={DIRECTIONS}
      className={styles.directionsIcon}
    />
  );
}
